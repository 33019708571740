import Icon from '@mdi/react';
import {
  mdiAlert,
  mdiAlertCircle,
  mdiArrowRightBold,
  mdiBookOpenOutline,
  mdiChartBar,
  mdiClockOutline,
  mdiCloseCircle,
  mdiCurrencyUsd,
  mdiFilter,
  mdiMagnify,
  mdiPercentOutline,
  mdiPrinter,
  mdiCommentEditOutline,
  mdiCommentEdit,
  mdiCalendarText,
  mdiCheckboxMarkedCircle,
  mdiAccount,
  mdiMessageAlert,
  mdiMapMarker,
  mdiAccountGroup,
  mdiCheckboxMarked,
  mdiInformation,
  mdiMapMarkerRadius,
  mdiCalendarMonth,
  mdiChevronLeft,
  mdiChevronRight,
  mdiPlusCircle,
  mdiPalette,
  mdiLightbulbOnOutline,
  mdiEmail,
  mdiLock,
  mdiCreditCard,
  mdiCellphone,
  mdiSilverwareForkKnife,
  mdiHelpCircleOutline,
  mdiFileDocument,
  mdiFileDocumentOutline,
  mdiBank,
  mdiDomain,
  mdiGauge,
  mdiChartBox,
  mdiCalendarClock,
  mdiIsland,
  mdiTimerCheck,
  mdiCalendarAccount,
  mdiPost,
  mdiAccountTie,
  mdiTimetable,
  mdiTimerCheckOutline,
  mdiBookClockOutline,
  mdiSwapHorizontal,
  mdiCheckboxMarkedCircleOutline,
  mdiRuler,
  mdiExclamation,
  mdiCellphoneCog,
  mdiCheckboxBlankCircle,
  mdiCheckDecagram,
  mdiDragHorizontal,
  mdiEyeOffOutline,
  mdiCheckBold,
  mdiText,
  mdiGoogle,
  mdiBarcodeScan,
  mdiNoteEdit,
  mdiNoteText,
  mdiNoteTextOutline,
  mdiNoteOutline,
  mdiAccountBadge,
  mdiAccountArrowUp,
  mdiAccountArrowDown,
  mdiAccountCog,
  mdiAccountCash,
  mdiShieldAccount,
  mdiBriefcaseAccount,
  mdiBriefcaseClock,
  mdiLockOpen,
  mdiSyncCircle,
  mdiSyncOff,
  mdiClipboardCheck,
  mdiChevronDoubleDown,
  mdiChevronDoubleUp,
  mdiFinance,
  mdiStarOutline,
  mdiStar,
  mdiSortAlphabeticalAscending,
  mdiSortClockAscendingOutline,
  mdiCog,
  mdiHelp,
  mdiClockTimeThree,
  mdiContentCopy,
  mdiFileDocumentEdit,
  mdiCalendarFilter,
  mdiCellphoneInformation,
  mdiTrashCanOutline,
  mdiFileEye,
  mdiCalendarToday,
  mdiCalendarAlert,
  mdiEye,
  mdiFileExport,
  mdiGestureSwipeHorizontal,
  mdiPlayCircle,
  mdiHelpCircle,
  mdiCogOutline,
  mdiFileCogOutline,
  mdiFileMarker,
  mdiFileCog,
  mdiCashClock,
  mdiSquareRounded,
  mdiFileEditOutline,
  mdiPencil,
  mdiCashPlus,
  mdiSort,
  mdiArrowUpRight,
  mdiFileMultipleOutline,
  mdiAccountRemove,
  mdiAccountPlus,
  mdiLightbulbOn,
  mdiEmoticonSickOutline,
  mdiFormatListGroup,
  mdiEmailOutline,
  mdiMessageOutline,
  mdiBellBadgeOutline,
  mdiRefreshCircle,
  mdiPhone,
  mdiMessageProcessing,
  mdiCakeVariant,
  mdiDotsHorizontal,
  mdiLaptop,
  mdiExportVariant,
  mdiChartGantt,
  mdiKeyboard,
  mdiEyeOff,
  mdiCloudRefreshVariant,
  mdiTableLarge,
  mdiMenuRight,
  mdiFileChart,
  mdiCalendarQuestion,
  mdiCalendarPlus,
  mdiBeach,
  mdiCashSync,
  mdiCalendarWeekBegin,
  mdiCodeGreaterThan
} from '@mdi/js';
import { ComponentType, ReactNode } from 'react';

type Props = {
  size?: number;
  className?: string;
  title?: string;
  color?: string;
};

function createIcon(icon: string): ComponentType<Props> {
  return function CustomIcon({ color, size = 1.5, className = '', title = '' }: Props) {
    return <Icon path={icon} size={size} className={className} title={title} color={color} />;
  };
}

export type CustomIcon = ReactNode;

export const PrinterIcon = createIcon(mdiPrinter);
export const AlertTriangleIcon = createIcon(mdiAlert);
export const AlertCircleIcon = createIcon(mdiAlertCircle);
// export const CloseCircleOutlineIcon = createIcon(mdiCloseCircleOutline);
export const CloseCircleIcon = createIcon(mdiCloseCircle);
export const ArrowRightBoldIcon = createIcon(mdiArrowRightBold);
export const MagnifyIcon = createIcon(mdiMagnify);
export const FilterIcon = createIcon(mdiFilter);
export const ClockOutlineIcon = createIcon(mdiClockOutline);
export const ClockTimeThreeIcon = createIcon(mdiClockTimeThree);
export const CurrencyIcon = createIcon(mdiCurrencyUsd);
export const PercentIcon = createIcon(mdiPercentOutline);
export const ChartIcon = createIcon(mdiChartBar);
export const BookOpenOutlineIcon = createIcon(mdiBookOpenOutline);
export const CommentEditOutlineIcon = createIcon(mdiCommentEditOutline);
export const CommentEditIcon = createIcon(mdiCommentEdit);
export const EmoticonSickOutlineIcon = createIcon(mdiEmoticonSickOutline);
export const PencilIcon = createIcon(mdiPencil);
export const CalendarIcon = createIcon(mdiCalendarText);
export const MenuRightIcon = createIcon(mdiMenuRight);
export const CalendarFilterIcon = createIcon(mdiCalendarFilter);
export const CheckboxMarkedCircleIcon = createIcon(mdiCheckboxMarkedCircle);
export const CheckboxMarkedCircleOutlineIcon = createIcon(mdiCheckboxMarkedCircleOutline);
export const CheckboxMarkedIcon = createIcon(mdiCheckboxMarked);
export const AccountIcon = createIcon(mdiAccount);
export const CashSyncIcon = createIcon(mdiCashSync);
export const CalendarWeekBeginIcon = createIcon(mdiCalendarWeekBegin);
export const AccountPlusIcon = createIcon(mdiAccountPlus);
export const CogOutlineIcon = createIcon(mdiCogOutline);
export const AccountTieIcon = createIcon(mdiAccountTie);
export const MessageAlertIcon = createIcon(mdiMessageAlert);
export const MessageOutlineIcon = createIcon(mdiMessageOutline);
export const MapMarkerIcon = createIcon(mdiMapMarker);
export const AccountGroupIcon = createIcon(mdiAccountGroup);
export const FileMarkerIcon = createIcon(mdiFileMarker);
export const InformationIcon = createIcon(mdiInformation);
export const MapMarkerRadiusIcon = createIcon(mdiMapMarkerRadius);
export const KeyboardIcon = createIcon(mdiKeyboard);
export const CalendarMonthIcon = createIcon(mdiCalendarMonth);
export const ChevronLeftIcon = createIcon(mdiChevronLeft);
export const ChevronRightIcon = createIcon(mdiChevronRight);
export const ChevronDoubleDownIcon = createIcon(mdiChevronDoubleDown);
export const ChevronDoubleUpIcon = createIcon(mdiChevronDoubleUp);
export const PlusCircleIcon = createIcon(mdiPlusCircle);
export const RefreshCircleIcon = createIcon(mdiRefreshCircle);
export const FileDocumentEditIcon = createIcon(mdiFileDocumentEdit);
export const FileEyeIcon = createIcon(mdiFileEye);
export const ContentCopyIcon = createIcon(mdiContentCopy);
export const PaletteIcon = createIcon(mdiPalette);
export const BellBadgeOutlineIcon = createIcon(mdiBellBadgeOutline);
export const LightBulbOnOutlineIcon = createIcon(mdiLightbulbOnOutline);
export const LightBulbOnIcon = createIcon(mdiLightbulbOn);
export const EmailIcon = createIcon(mdiEmail);
export const EmailOutlineIcon = createIcon(mdiEmailOutline);
export const LockIcon = createIcon(mdiLock);
export const LockOpenIcon = createIcon(mdiLockOpen);
export const CreditCardIcon = createIcon(mdiCreditCard);
export const CellphoneIcon = createIcon(mdiCellphone);
export const ForkKnifeIcon = createIcon(mdiSilverwareForkKnife);
export const HelpCircleOutlineIcon = createIcon(mdiHelpCircleOutline);
export const HelpIcon = createIcon(mdiHelp);
export const HelpCircleIcon = createIcon(mdiHelpCircle);
export const FileDocumentIcon = createIcon(mdiFileDocument);
export const ArrowUpRightIcon = createIcon(mdiArrowUpRight);
export const AccountRemoveIcon = createIcon(mdiAccountRemove);
export const FileMultipleOutlinecon = createIcon(mdiFileMultipleOutline);
export const FileDocumentOutlineIcon = createIcon(mdiFileDocumentOutline);
export const DomainIcon = createIcon(mdiDomain);
export const BankIcon = createIcon(mdiBank);
export const DashboardIcon = createIcon(mdiGauge);
export const ChartBoxIcon = createIcon(mdiChartBox);
export const CalendarClockIcon = createIcon(mdiCalendarClock);
export const CakeVariantIcon = createIcon(mdiCakeVariant);
export const IslandIcon = createIcon(mdiIsland);
export const TimerCheckIcon = createIcon(mdiTimerCheck);
export const CalendarAccountIcon = createIcon(mdiCalendarAccount);
export const PostIcon = createIcon(mdiPost);
export const TimetableIcon = createIcon(mdiTimetable);
export const TableLargeIcon = createIcon(mdiTableLarge);
export const TimerCheckOutlineIcon = createIcon(mdiTimerCheckOutline);
export const BookClockOutlineIcon = createIcon(mdiBookClockOutline);
export const SwapHorizontalIcon = createIcon(mdiSwapHorizontal);
export const RulerIcon = createIcon(mdiRuler);
export const ExclamationIcon = createIcon(mdiExclamation);
export const AccountSetupIcon = createIcon(mdiCellphoneCog);
export const CheckboxBlankCircleIcon = createIcon(mdiCheckboxBlankCircle);
export const CheckDecagramIcon = createIcon(mdiCheckDecagram);
export const SquareRoundedIcon = createIcon(mdiSquareRounded);
export const CodeGreaterThanIcon = createIcon(mdiCodeGreaterThan);
export const DragHorizontalIcon = createIcon(mdiDragHorizontal);
export const EyeOffOutlineIcon = createIcon(mdiEyeOffOutline);
export const EyeOffIcon = createIcon(mdiEyeOff);
export const CloudRefreshVariantIcon = createIcon(mdiCloudRefreshVariant);
export const EyeIcon = createIcon(mdiEye);
export const CheckBoldIcon = createIcon(mdiCheckBold);
export const TextIcon = createIcon(mdiText);
export const GoogleIcon = createIcon(mdiGoogle);
export const BarcodeScanIcon = createIcon(mdiBarcodeScan);
export const NoteEditIcon = createIcon(mdiNoteEdit);
export const NoteTextIcon = createIcon(mdiNoteText);
export const NoteTextOutlineIcon = createIcon(mdiNoteTextOutline);
export const NoteOutlineIcon = createIcon(mdiNoteOutline);
export const AccountBadgeIcon = createIcon(mdiAccountBadge);
export const AccountArrowUpIcon = createIcon(mdiAccountArrowUp);
export const AccountArrowDownIcon = createIcon(mdiAccountArrowDown);
export const AccountConfigIcon = createIcon(mdiAccountCog);
export const AccountCashIcon = createIcon(mdiAccountCash);
export const ShieldAccountIcon = createIcon(mdiShieldAccount);
export const BriefcaseAccountIcon = createIcon(mdiBriefcaseAccount);
export const BriefcaseClockIcon = createIcon(mdiBriefcaseClock);
export const SyncCircleIcon = createIcon(mdiSyncCircle);
export const SyncOffIcon = createIcon(mdiSyncOff);
export const ClipboardCheckIcon = createIcon(mdiClipboardCheck);
export const FinanceIcon = createIcon(mdiFinance);
export const FileChartIcon = createIcon(mdiFileChart);
export const StarOutlineIcon = createIcon(mdiStarOutline);
export const StarIcon = createIcon(mdiStar);
export const SortAlphabeticalAscendingIcon = createIcon(mdiSortAlphabeticalAscending);
export const SortClockAscendingOutlineIcon = createIcon(mdiSortClockAscendingOutline);
export const ChartGanttIcon = createIcon(mdiChartGantt);
export const ConfigIcon = createIcon(mdiCog);
export const CellphoneInformationIcon = createIcon(mdiCellphoneInformation);
export const TrashCanOutlineIcon = createIcon(mdiTrashCanOutline);
export const CalendarTodayIcon = createIcon(mdiCalendarToday);
export const CalendarAlertIcon = createIcon(mdiCalendarAlert);
export const FileExportIcon = createIcon(mdiFileExport);
export const GestureSwipeHorizontalIcon = createIcon(mdiGestureSwipeHorizontal);
export const PlayCircleIcon = createIcon(mdiPlayCircle);
export const FileCogOutlineIcon = createIcon(mdiFileCogOutline);
export const FileCogIcon = createIcon(mdiFileCog);
export const CashClockIcon = createIcon(mdiCashClock);
export const CashPlusIcon = createIcon(mdiCashPlus);
export const FileEditOutlineIcon = createIcon(mdiFileEditOutline);
export const SortIcon = createIcon(mdiSort);
export const FormatListGroupIcon = createIcon(mdiFormatListGroup);
export const PhoneIcon = createIcon(mdiPhone);
export const MessageProcessingIcon = createIcon(mdiMessageProcessing);
export const DotsHorizontalIcon = createIcon(mdiDotsHorizontal);
export const LaptopIcon = createIcon(mdiLaptop);
export const ExportVariantIcon = createIcon(mdiExportVariant);
export const CalendarQuestioIcon = createIcon(mdiCalendarQuestion);
export const CalendarPlusIcon = createIcon(mdiCalendarPlus);
export const BeachIcon = createIcon(mdiBeach);
