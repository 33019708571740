import React, { lazy, ReactNode, Suspense, useEffect } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Outlet } from 'react-router-dom';
import GlobalLoader from 'components/loader/global';
import { QueryClientProvider } from '@tanstack/react-query';
import { appURLs, queryClient } from 'api';
import { ErrorComponent } from 'pages/ErrorComponent';
import axios, { AxiosError } from 'axios';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Protected } from 'pages/_protected';
import { Toasts } from 'components/toasts';
import { HelmetProvider } from 'react-helmet-async';
import Loader from 'components/loader';
import { whichDomain } from 'helpers/which-domain';
import { CustomHelmet } from 'components/custom-helmet';
import { atom, useAtom } from 'jotai';
import Logout from 'pages/logout';
import Sites from 'pages/sites';
import { invalidateQueriesLoader, mainLoader, permissionLoader, redirectLoader } from 'pages/loader';
import { selfOnboardLoader } from 'pages/self-onboard/loader';
import { getSessionID, setSessionID } from 'helpers/session-identifier';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { round } from 'lib/dayjs';
import { PERMISSION } from '@types';
import Hotjar from '@hotjar/browser';
import { GTMScript } from 'GTM';
import XeroAuthVerifier from './pages/xero/auth-verifier';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useUser } from 'hooks/api/user';
import { setLocalAppSessionId, setSharedAppSessionId } from 'helpers/app-session-id';

const Dashboard = lazy(() => import('pages/dashboard'));
const ActivityFeed = lazy(() => import('pages/activity-feed'));
const Payroll = lazy(() => import('pages/payroll'));
const PayrollSettings = lazy(() => import('pages/payroll/settings'));
const Staff = lazy(() => import('pages/staff'));
const Positions = lazy(() => import('pages/positions'));
const Login = lazy(() => import('pages/login'));
const SelfOnboard = lazy(() => import('pages/self-onboard'));
const SelfOnboardHome = lazy(() => import('pages/self-onboard/home'));
const SelfOnboardPersonalInformation = lazy(() => import('pages/self-onboard/personal-information'));
const SelfOnboardBankDetails = lazy(() => import('pages/self-onboard/bank-details'));
const SelfOnboardTFNDeclaration = lazy(() => import('pages/self-onboard/tfn-declaration'));
const SelfOnboardSuperannuation = lazy(() => import('pages/self-onboard/superannuation'));
const SelfOnboardCompanyDocuments = lazy(() => import('pages/self-onboard/company-documents'));
const MyCalendar = lazy(() => import('pages/my-calendar'));
const Reports = lazy(() => import('pages/reports'));
const CompleteShiftReport = lazy(() => import('pages/reports/complete-shift-report'));
const StaffDetailsReport = lazy(() => import('pages/reports/staff-details-report'));
const ApprovedLeaveReport = lazy(() => import('pages/reports/approved-leave-report'));
const Roster = lazy(() => import('pages/roster'));
const Rosters = lazy(() => import('pages/rosters'));
const Registration = lazy(() => import('pages/registration'));
const WelcomeBack = lazy(() => import('pages/welcomeback'));
const HRDocuments = lazy(() => import('pages/hr-documents'));
const MyShifts = lazy(() => import('pages/my-shifts'));
const MyShiftsDetail = lazy(() => import('pages/my-shifts/shift-details'));
const DownloadApps = lazy(() => import('pages/download-apps'));

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(round);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);

if (window.location.pathname === '/redirect/') {
  const page = window.location.search.slice(1).split('#')[0];
  const sid = window.location.hash.slice(1);
  setSessionID(sid);
  window.location.href = `/${page}`;
}

const { apiURL } = appURLs();
// let auth: string | null;
axios.interceptors.request.use(config => {
  // if (!auth) {
  const auth = getSessionID();
  // }

  if (auth) {
    // @ts-ignore
    config.headers.Authorization = auth;
  }

  return config;
});

axios.defaults.headers.common['Content-Type'] = 'text/plain';
axios.defaults.baseURL = apiURL;
axios.interceptors.response.use(
  success => success.data,
  // success => {
  //   return { ...success.data, headers: success.headers }
  // },
  (error: AxiosError) => {
    if (
      !window.location.href.includes('/login') &&
      !window.location.href.includes('/registration') &&
      error.response?.status === 401 &&
      error.response?.statusText === 'Unauthorized'
    ) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

if (whichDomain() === 'rosterelf') {
  const siteId = 3375945;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={'/'} element={<Outlet />} errorElement={<ErrorComponent />}>
      <Route
        path={'login'}
        element={
          <Suspense fallback={<Loader fullscreen />}>
            <Login />
          </Suspense>
        }
      />

      <Route
        path={'registration'}
        element={
          <Suspense fallback={<Loader fullscreen />}>
            <Registration />
          </Suspense>
        }
      />

      <Route
        path={'welcomeback'}
        element={
          <Suspense fallback={<Loader fullscreen />}>
            <WelcomeBack />
          </Suspense>
        }
      />

      <Route path={'redirect'} element={null} />

      <Route path={'error'} element={<ErrorComponent />} />
      <Route loader={mainLoader(queryClient)} path={'/'} element={<Protected />} shouldRevalidate={() => false}>
        <Route path={'xero/v2/auth-verifier'} element={<XeroAuthVerifier />} />
        <Route path={'logout'} element={<Logout />} />
        <Route
          path={'/'}
          loader={redirectLoader(queryClient)}
          shouldRevalidate={() => false}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <Dashboard />
            </Suspense>
          }
        />

        <Route
          path={'activity-feed'}
          loader={permissionLoader(queryClient, [PERMISSION.ADMIN])}
          shouldRevalidate={() => false}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <ActivityFeed />
            </Suspense>
          }
        />

        <Route
          path={'my-calendar'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <MyCalendar />
            </Suspense>
          }
        />

        <Route shouldRevalidate={() => false} path={'my-shifts'}>
          <Route
            index
            element={
              <Suspense fallback={<Loader fullscreen />}>
                <MyShifts />
              </Suspense>
            }
          />
          <Route
            path={':shiftId'}
            element={
              <Suspense fallback={<Loader fullscreen />}>
                <MyShiftsDetail />
              </Suspense>
            }
          />
        </Route>

        <Route
          loader={permissionLoader(queryClient, [PERMISSION.MANAGER, PERMISSION.ACCOUNTANT])}
          shouldRevalidate={() => false}
          path={'reports'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <Reports />
            </Suspense>
          }
        />

        <Route
          shouldRevalidate={() => false}
          path={'rosters'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <Rosters />
            </Suspense>
          }
        />

        <Route
          loader={permissionLoader(queryClient, [PERMISSION.MANAGER, PERMISSION.ACCOUNTANT])}
          shouldRevalidate={() => false}
          path={'reports/complete-shift-report'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <CompleteShiftReport />
            </Suspense>
          }
        />

        <Route
          loader={permissionLoader(queryClient, [PERMISSION.MANAGER, PERMISSION.ACCOUNTANT])}
          shouldRevalidate={() => false}
          path={'reports/staff-details-report'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <StaffDetailsReport />
            </Suspense>
          }
        />

        <Route
          loader={permissionLoader(queryClient, [PERMISSION.MANAGER, PERMISSION.ACCOUNTANT])}
          shouldRevalidate={() => false}
          path={'reports/approved-leave-report'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <ApprovedLeaveReport />
            </Suspense>
          }
        />

        <Route
          loader={permissionLoader(queryClient, [PERMISSION.MANAGER_ASSISTANT, PERMISSION.MANAGER, PERMISSION.ACCOUNTANT])}
          shouldRevalidate={() => false}
          path={'staff'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <Staff />
            </Suspense>
          }
        />

        <Route
          loader={invalidateQueriesLoader(
            queryClient,
            [['/schedule/positions'], ['/schedule/sites?full=1'], ['/schedule/staff']],
            [PERMISSION.MANAGER_ASSISTANT, PERMISSION.MANAGER, PERMISSION.ACCOUNTANT]
          )}
          shouldRevalidate={() => false}
          path={'roster'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <Roster />
            </Suspense>
          }
        />

        <Route
          loader={permissionLoader(queryClient, [PERMISSION.MANAGER, PERMISSION.ACCOUNTANT])}
          shouldRevalidate={() => false}
          path={'positions'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <Positions />
            </Suspense>
          }
        />

        <Route
          loader={permissionLoader(queryClient, [PERMISSION.ACCOUNTANT, PERMISSION.MANAGER])}
          shouldRevalidate={() => false}
          path={'sites'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <Sites />
            </Suspense>
          }
        />

        <Route
          shouldRevalidate={() => false}
          path={'download-apps'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <DownloadApps />
            </Suspense>
          }
        />

        <Route
          loader={permissionLoader(queryClient, [PERMISSION.MANAGER, PERMISSION.ACCOUNTANT])}
          shouldRevalidate={() => false}
          path={'hr-documents'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <HRDocuments />
            </Suspense>
          }
        />

        <Route loader={permissionLoader(queryClient, [PERMISSION.MANAGER, PERMISSION.MANAGER_ASSISTANT, PERMISSION.ACCOUNTANT])} shouldRevalidate={() => false} path={'payroll'}>
          <Route
            index
            element={
              <Suspense fallback={<Loader fullscreen />}>
                <Payroll />
              </Suspense>
            }
          />
          <Route path={'settings'}>
            <Route
              index
              element={
                <Suspense fallback={<Loader fullscreen />}>
                  <PayrollSettings />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route
          loader={selfOnboardLoader()}
          path={'self-onboard/:staffId'}
          element={
            <Suspense fallback={<Loader fullscreen />}>
              <SelfOnboard />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Loader fullscreen />}>
                <SelfOnboardHome />
              </Suspense>
            }
          />
          <Route
            path={'personal-information'}
            element={
              <Suspense fallback={<Loader fullscreen />}>
                <SelfOnboardPersonalInformation />
              </Suspense>
            }
          />
          <Route
            path={'bank-details'}
            element={
              <Suspense fallback={<Loader fullscreen />}>
                <SelfOnboardBankDetails />
              </Suspense>
            }
          />
          <Route
            path={'tfn-declaration'}
            element={
              <Suspense fallback={<Loader fullscreen />}>
                <SelfOnboardTFNDeclaration />
              </Suspense>
            }
          />
          <Route
            path={'superannuation-info'}
            element={
              <Suspense fallback={<Loader fullscreen />}>
                <SelfOnboardSuperannuation />
              </Suspense>
            }
          />
          <Route
            path={'company-document'}
            element={
              <Suspense fallback={<Loader fullscreen />}>
                <SelfOnboardCompanyDocuments />
              </Suspense>
            }
          />
        </Route>
      </Route>
    </Route>
  )
);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Bootstrap>
          <GTMScript />
          <GlobalLoader>
            <RouterProvider router={router} />
            <Toasts />
          </GlobalLoader>
        </Bootstrap>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

interface Theme {
  name: string;
  folder: string;
  primaryColor: string;
  secondaryColor: string;
}
export const themeAtom = atom<Theme>(null as unknown as Theme);
export const useAppTheme = () => useAtom(themeAtom)[0];
const themes: Record<string, Theme> = {
  rosterelf: {
    name: 'RosterElf',
    folder: 'rosterelf',
    primaryColor: '#33a144',
    secondaryColor: '#57cf72'
  },
  clubshr: {
    name: 'ClubsHR',
    folder: 'clubshr',
    primaryColor: '#016792',
    secondaryColor: '#25aae1'
  }
};

interface BootstrapProps {
  children: ReactNode;
}
function Bootstrap({ children }: BootstrapProps) {
  const [theme, setTheme] = useAtom(themeAtom);

  const { data: user } = useUser();
  const scope = user?.userInfo?.scope;

  if (window) window.dataLayer = window.dataLayer || [];

  useEffect(() => {
    const axiosInterceptor = axios.interceptors.request.use(
      config => {
        const allowedPaths = ['/staff/all', '/get-registration-page', '/services/payroll-settings-detail'];
        const ignorePaths = ['/services/payroll/start-template', '/services/get-payroll-settings'];

        if (config.method === 'post') {
          if (!ignorePaths.some(path => config.url?.includes(path))) {
            const newSharedAppSessionId = Date.now().toString();
            setLocalAppSessionId(newSharedAppSessionId);
            setSharedAppSessionId(newSharedAppSessionId);
          }

          if (scope && scope === 'READ_ONLY') {
            if (!allowedPaths.some(path => config?.url?.endsWith(path))) {
              console.error(config?.url);
              throw new Error('User is not allowed.');
            }
          }
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(axiosInterceptor);
    };
  }, [scope]);

  useEffect(() => {
    const themeName = whichDomain();
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = '/theme/' + themeName + '/style.css';

    const onload = () => {
      link.removeEventListener('load', onload);
      const theme = themes[themeName];
      document.getElementsByTagName('html')[0].classList.add(theme.folder);
      setTheme(theme);
    };

    link.addEventListener('load', onload);

    document.querySelector('head')?.prepend(link);
  }, [setTheme]);

  if (!theme) return null;

  const { name, folder, primaryColor, secondaryColor } = theme;

  return (
    <>
      {children}
      <CustomHelmet>
        <link rel="icon" href={`/theme/${folder}/images/favicon.ico`} />

        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/12.9__iPad_Pro_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/11__iPad_Pro__10.5__iPad_Pro_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/10.9__iPad_Air_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/10.5__iPad_Air_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/10.2__iPad_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/iPhone_14_Pro_Max_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/iPhone_14_Pro_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/iPhone_11__iPhone_XR_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={`/theme/${folder}/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/12.9__iPad_Pro_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/11__iPad_Pro__10.5__iPad_Pro_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/10.9__iPad_Air_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/10.5__iPad_Air_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/10.2__iPad_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/iPhone_14_Pro_Max_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/iPhone_14_Pro_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/iPhone_11__iPhone_XR_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={`/theme/${folder}/splash_screens/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png`}
        />
        <meta name="apple-mobile-web-app-title" content={`${name}`} />
        <meta name="application-name" content={`${name}`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`/theme/${folder}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/theme/${folder}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/theme/${folder}/favicon-16x16.png`} />
        <link rel="manifest" href={`/theme/${folder}/site.webmanifest`} />
        <link rel="mask-icon" href={`/theme/${folder}/safari-pinned-tab.svg`} color={secondaryColor} />
        <meta name="msapplication-TileColor" content={secondaryColor} />
        <meta name="msapplication-config" content={`/theme/${folder}/browserconfig.xml`} />
        <meta name="theme-color" content={primaryColor} />
      </CustomHelmet>
    </>
  );
}

export default App;
